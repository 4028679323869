import { HttpService } from "./common";
import {
  QualityAuditsDeliveredByAuditorModel, 
  AuditorFilterModel,
  AuditorLookupModel,
  PagedCollectionFilter,
  PagedCollection,
} from "@/core/models";

export const AuditorService = {

  getAuditsDelivered(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<QualityAuditsDeliveredByAuditorModel>>(
      "/auditors/audits-delivered", 
      { params: filter });
  },

  getLookupData()
  {
    return HttpService.getData<AuditorLookupModel>(
      "/auditors/lookup-data");
  }
};

import { SimpleLookupModel } from "@/core/models";

export const Lookups = {
  getCertificateStatuses(root: any): SimpleLookupModel[] {
    return [
      {id: 0, text: root.$t("profileCourses.validCertLookupAll").toString(), code: "A"},
      {id: 1, text: root.$t("profileCourses.validCertLookupCurrent").toString(), code: "C"},
      {id: 2, text: root.$t("profileCourses.validCertLookupExpired").toString(), code: "E"},
      {id: 3, text: root.$t("profileCourses.validCertLookupInProgress").toString(), code: "I"},
    ];
  },

  getExpiryLookups(): SimpleLookupModel[] {
    return [
      { id: 7, text: "1 Week", code: "" },
      { id: 14, text: "2 Weeks", code: "" },
      { id: 30, text: "1 Month", code: "" },
      { id: 90, text: "3 Months", code: "" },
      { id: 180, text: "6 Month", code: "" },
    ];
  },
};

import { HttpService, DownloadProvider} from "./common";
import {
  CourseModel,
  CourseCollectionModel,
  CourseMaterialModel,
  CourseQuestionModel,
  CourseQuestionPageModel,
  CourseCertificateModel,
  CourseCertificateTemplateCollectionModel,
  CourseCertificateTemplateModel,
  CourseCertificateTemplateLocaleModel,
  IrisQuestionTypeModel,
  CourseLookupModel,
  PagedCollectionFilter,
  PagedCollection,
  FileDownloadModel,
  SimpleLookupModel,
} from "@/core/models";
import { UploadService } from ".";

export const CourseService = {
  list() {
    return HttpService.getData<CourseModel[]>("/courses");
  },

  async find(search: string) {
    if (!search) {
      return [];
    }

    const result = await this.filterCourses({
      page: 1,
      pageSize: 0,
      search,
      sortBy: "name",
    });

    return result.items;
  },

  filterCourses(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<CourseCollectionModel>>(
      "/courses/filter",
      { params: filter },
    );
  },

  filterLicensedCourses(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<CourseCollectionModel>>(
      "/courses/licensed/filter",
      { params: filter },
    );
  },

  getById(id: any) {
    return HttpService.getData<CourseModel>(`/courses/${id}`);
  },

  getForManualCertification() {
    return HttpService.getData<SimpleLookupModel[]>('/courses/manual-certification');
  },

  getByCourseSessionId(courseSessionId: any) {
    return HttpService.getData<CourseModel>(`/courseSessions/${courseSessionId}/course`);
  },

  getQuestionTypes() {
    return HttpService.getData<IrisQuestionTypeModel[]>(`/irisquestiontypes`);
  },

  getPagesByCourseId(id: number) {
    return HttpService.getData<CourseQuestionPageModel[]>(`/courses/${id}/questions/pages`);
  },

  getPageById(id: number, pageId: number) {
    return HttpService.getData<CourseQuestionPageModel>(`/courses/${id}/questions/pages/${pageId}`);
  },

  addPage(courseId: any, model: CourseQuestionPageModel) {
    return HttpService.postModel(`/courses/${courseId}/questions/pages`, model);
  },

  updatePage(courseId: any, model: CourseQuestionPageModel) {
    return HttpService.putModel(`/courses/${courseId}/questions/pages`, model);
  },

  deletePage(courseId: any, courseQuestionPageId: number) {
    return HttpService.delete(`/courses/${courseId}/questions/pages/${courseQuestionPageId}`);
  },

  sortPages(courseId: any, model: any) {
    return HttpService.put(`/courses/${courseId}/questions/pages/sort`, model);
  },

  getQuestionsByCourseId(id: number, pageId: number, locale: string) {
    return HttpService.getData<CourseQuestionModel[]>(`/courses/${id}/questions/?pageId=${pageId}&locale=${locale}`);
  },

  getQuestionById(
    courseId: any,
    courseQuestionId: any,
    locale: string,
    alternativeLocale: string) {
    return HttpService.getData<CourseQuestionModel>(
      `/courses/${courseId}/questions/${courseQuestionId}` +
      `?locale=${locale}&altLocale=${alternativeLocale}`);
  },

  addQuestion(courseId: any,  model: CourseQuestionModel) {
    return HttpService.postModel(`/courses/${courseId}/questions`, model);
  },

  updateQuestion(courseId: any,  model: CourseQuestionModel) {
    return HttpService.putModel(`/courses/${courseId}/questions`, model);
  },

  deleteQuestion(courseId: any, courseQuestionId: number) {
    return HttpService.delete(`/courses/${courseId}/questions/${courseQuestionId}`);
  },

  getlookups() {
    return HttpService.getData<CourseLookupModel>("/courses/formlookupdata");
  },

  getlookupsByLicense() {
    return HttpService.getData<CourseLookupModel>("/courses/licensed/formlookupdata");
  },

  addCourse(model: CourseModel) {
    return HttpService.postModel("/courses", model);
  },

  updateCourse(model: CourseModel) {
    return HttpService.putModel("/courses", model);
  },

  getCertificateTemplates(courseId: number) {
    return HttpService.getData<CourseCertificateModel[]>(
      `/courses/${courseId}/certificates`,
    );
  },

  updateCourseCertificate(model: CourseCertificateModel) {
    return HttpService.putModel(`/courses/${model.courseId}/certificates`, model);
  },

  async exportFeedback(startDate: string, endDate: string) {
    const response = await HttpService.getData<FileDownloadModel>(
      "courses/exportFeedback",
      { params: { startDate, endDate }});

    DownloadProvider.downloadResponse(response);
  },

  getCourseMaterials(courseId: number, locale: string) {
    const url = `courses/materials/${courseId}/${locale}`;

    return HttpService.getData<CourseMaterialModel[]>(url);
  },

  addCourseMaterial(model: CourseMaterialModel) {
    return HttpService.postModel(`/courses/materials`, model);
  },

  deleteCourseMaterial(courseMaterialId: number) {
    return HttpService.delete(`/courses/materials/${courseMaterialId}`);
  },

  async exportExamQuestionsXlf(courseId: number, sourceLocale: string, targetLocale: string) {
    const url = `/courses/${courseId}/export-exam-xlf/${sourceLocale}/${targetLocale}`;
    const response = await HttpService.getData<FileDownloadModel>(url);

    DownloadProvider.downloadResponse(response);
  },

  async uploadExamXlf(courseId: number, targetLocale: string, file: any) {
    const url = `/courses/${courseId}/upload-exam-xlf/${targetLocale}`;

    return await UploadService.uploadAsAny(url, file) as boolean;
  },

  getCourseCertificateTemplates(courseId: number) {
    const url = `/courses/certificate-templates/course/${courseId}`;

    return HttpService.getData<CourseCertificateTemplateCollectionModel[]>(url);
  },

  getCourseCertificateTemplateById(certificateTemplateId: number) {
    const url = `/courses/certificate-templates/${certificateTemplateId}`;

    return HttpService.getData<CourseCertificateTemplateModel>(url);
  },

  addCourseCertificateTemplate(courseId: number, model: CourseCertificateTemplateModel) {
    return HttpService.postModel(`/courses/certificate-templates/${courseId}`, model);
  },

  updateCourseCertificateTemplate(model: CourseCertificateTemplateModel) {
    return HttpService.putModel("/courses/certificate-templates", model);
  },

  getCourseCertificateTemplateLocaleById(certificateTemplateLocaleId: number) {
    const url = `/courses/certificate-templates/locales/${certificateTemplateLocaleId}`;

    return HttpService.getData<CourseCertificateTemplateLocaleModel>(url);
  },

  addCourseCertificateTemplateLocale(
    certificateTemplateId: number,
    model: CourseCertificateTemplateLocaleModel) {

    return HttpService.postModel(
      `/courses/certificate-templates/locales/${certificateTemplateId}`,
      model);
  },

  updateCourseCertificateTemplateLocale(model: CourseCertificateTemplateLocaleModel) {
    return HttpService.putModel("/courses/certificate-templates/locales", model);
  },
};

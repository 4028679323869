import Vue from "vue";
import Router from "vue-router";

import {
  LoginRouter,
  loginRoutes } from "./login.router";
import { courseRoutes } from "./course.router";
import { certificateRoutes } from "./certification.router";
import { regionRoutes } from "./region.router";
import { unionRoutes } from "./union.router";
import { emailTemplateRoutes } from "./email-template.router";
import { licenseRoutes } from "./license.router";
import { workforceRoutes } from "./workforce.router";
import { fileshareRoutes } from "./fileshare.router";
import { courseSessionsRoutes } from "./course-sessions.router";
import { questionnaireRoutes } from "./questionnaire.router";
import { userRoutes } from "./user.router";
import { profileRoutes } from "./profile.router";
import { correspondenceRoutes } from "./correspondence.router";
import { complianceRoutes } from "./compliance.router";
import { agentRoutes } from "./agents.router";
import { AuthorisationUtility } from "@/core/utilities/authorisation.utility";

Vue.use(Router);

const homeRoutes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Dashboard",
      tag: "navigation.dashboard",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "general" */ "@/views/Home.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: { title: "About" },
    component: () =>
      import(/* webpackChunkName: "general" */ "@/views/About.vue"),
  },
  {
    path: "/about/release",
    name: "release",
    meta: { title: "Release Notes" },
    component: () =>
      import(/* webpackChunkName: "general" */ "@/views/Release.vue"),
  },
  {
    path: "/permission-denied",
    name: "permissionDenied",
    meta: { title: "Permission Denied" },
    component: () =>
      import(/* webpackChunkName: "general" */ "@/views/general/PermissionDenied.vue"),
  },
  {
    path: "/accept-terms-and-conditions",
    name: "acceptTermsAndConditions",
    meta: {
      layout: "empty",
      title: "Accept educator terms and conditions",
      tag: "routeTags.acceptEducatorTerms",
      localise: true,
    },
    component: () =>
      import(/* webpackChunkName: "general" */ "@/views/general/AcceptTermsAndConditions.vue"),
  },
];

const routes = [
  ...homeRoutes,
  ...loginRoutes,
  ...courseRoutes,
  ...certificateRoutes,
  ...regionRoutes,
  ...unionRoutes,
  ...emailTemplateRoutes,
  ...licenseRoutes,
  ...workforceRoutes,
  ...fileshareRoutes,
  ...courseSessionsRoutes,
  ...questionnaireRoutes,
  ...userRoutes,
  ...profileRoutes,
  ...correspondenceRoutes,
  ...complianceRoutes,
  ...agentRoutes,
];

const router = new Router({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const allowAnonymous = to.matched.some((record) => record.meta.allowAnonymous);
  const isAuthenticated = router.app.$msal.isAuthenticated();

  // console.log("Router From", to, from, allowAnonymous, isAuthenticated);

  if (!allowAnonymous) {
    if (!isAuthenticated) {
      // If a non-anonymoyus route and user not authenticated, redirect to login
      LoginRouter.redirectToLogin(to, router);

      return;
    }
    // If non-anonymous route and user authenticated, don't do anything
  } else {
    if (isAuthenticated) {
      // if anonymous route, and user authenticated, redirect to home, don't want to 
      // bother the user
      next("/");

      return;
    }
  }

  if (isAuthenticated && !to.name) {
    next("/");

    return;
  }

  // TODO: handle redirecting to /login from inside the app (as opposed to from B2C)

  if (!!to.meta.roles) {
    if (!AuthorisationUtility.isInUserRole(to.meta.roles)) {
      next("/permission-denied");

      return;
    }
  }

  if (!!to.meta.faceTofaceMode && !AuthorisationUtility.isInFaceToFaceMode()) {
    // Cannot access a face to face route when not in Face to face mode
    next("/permission-denied");
    
    return;
  }

  router.app.$prevRoute = from;
  next();
});

export default router;

import { HttpService} from "./common";
import {
  AgentApplicationModel,
  AgentApplicationCollectionModel,
  AgentApplicationListLookupModel,
  UpdateAgentApplicationLookupModel,
  PagedCollectionFilter,
  PagedCollection,
  
} from "@/core/models";

export const AgentApplicationService = {
  filter(filter: PagedCollectionFilter) {
    return HttpService.getData<PagedCollection<AgentApplicationCollectionModel>>(
      "/agent-applications/filter",
      { params: filter },
    );
  },

  getListLookups() {
    return HttpService.getData<AgentApplicationListLookupModel>(
      "/agent-applications/list/lookups",
    );
  },

  getApplication(id: number) {
    return HttpService.getData<AgentApplicationModel>(
      "/agent-applications/" + id,
    );
  },

  postApplication(model: AgentApplicationModel) {
    return HttpService.postModel("/agent-applications", model);
  },

  getUpdateApplicationLookups() {
    return HttpService.getData<UpdateAgentApplicationLookupModel>(
      "/agent-applications/update/lookups",
    );
  },
}




































































































































































































import { Component } from "vue-property-decorator";

import { LicenseType } from "@/core/constants";
import AppVue from "@/apps/AppVue.vue";

@Component
export default class Sidebar extends AppVue {
  isCollapsed: boolean = false;

  get activeLicenseListUrl() {
    return `/licenses?statusId=${LicenseType.Active}`;
  }

  get showCourseSessionAdmin() {
    return this.isAdmin
      || this.isUnionTrainingManager
      || this.isUnionTrainingAdministrator
      || this.isRegionalTrainingManager
      || this.isGlobalTrainingManager;
  }

  protected expandMenu(): void {
    this.isCollapsed = false;
  }

  protected collapseMenu(): void {
    this.isCollapsed = true;
  }

}

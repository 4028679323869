import { RoleName } from "@/core/constants";

export const AgentApplicationListViewName = "agentApplicationList";
export const AgentApplicationFormViewName = "agentApplicationForm";

export const agentRoutes = [
  {
    path: "/agents/applications",
    name: AgentApplicationListViewName,
    meta: {
      title: "Agent Applications",
      tag: "agents.applications",
      roles: `${RoleName.AgentsAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "agents" */ "@/views/agents/AgentApplicationList.vue"),
  },

  {
    path: "/agents/applications/:id",
    name: AgentApplicationFormViewName,
    meta: {
      title: "Update Agent Application",
      tag: "agents.updateAgentapplication",
      roles: `${RoleName.AgentsAdministrator}`,
    },
    component: () =>
      import(/* webpackChunkName: "agents" */ "@/views/agents/AgentApplicationForm.vue"),
  },
]
import { RoleName } from "@/core/constants";

export const workforceRoutes = [
  {
    path: "/workforce/educators",
    name: "educatorList",
    meta: {
      title: "Educators",
      tag: "common.educators",
      localise: true,
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.UnionTrainingManager},` +
        `${RoleName.UnionAdministrator},` +
        `${RoleName.IrisAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "workforce" */ "@/views/workforce/EducatorList.vue"),
  },

  {
    path: "/workforce/auditors",
    name: "auditorList",
    meta: {
      title: "Auditors",
      tag: "common.auditors",
      localise: true,
      faceTofaceMode: true,
      roles: `${RoleName.RegionalTrainingManager},` +
        `${RoleName.GlobalTrainingManager},` +
        `${RoleName.UnionTrainingManager},` +
        `${RoleName.UnionAdministrator},` +
        `${RoleName.IrisAdministrator}` },
    component: () =>
      import(/* webpackChunkName: "workforce" */ "@/views/workforce/AuditorList.vue"),
  },

];

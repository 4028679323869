import { Vue } from "vue-property-decorator";

import { UploadResultModel } from "@/core/models";
import { HttpService } from ".";

export const UploadService = new Vue({
  methods: {
    uploadLogo(file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload("/upload/logo", file, onUploadProgress);
    },

    uploadAsset(file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload("/upload/asset", file, onUploadProgress);
    },

    uploadEmailAttachment(file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload("/upload/emailattachment", file, onUploadProgress);
    },

    uploadCourseCertificate(file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload("/upload/courseCertificate", file, onUploadProgress);
    },

    uploadCourseCertificateAsset(file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload("/upload/courseCertificateAssets", file, onUploadProgress);
    },

    uploadCourseMaterial(file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload("/upload/courseMaterial", file, onUploadProgress);
    },

    uploadUserPhoto(userId: number, file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload(`/upload/user-photo/${userId}`, file, onUploadProgress);
    },

    uploadAgentApplicationFile(file: any, onUploadProgress?: (progressEvent: any) => void) {
      return this.upload("/upload/agentApplicationFile", file, onUploadProgress);
    },

    async upload(route: string, file: any, onUploadProgress?: (progressEvent: any) => void) {
      return await this.uploadAsAny(
        route,
        file,
        onUploadProgress) as UploadResultModel;
    },

    async uploadAsAny(
      route: string,
      file: any,
      onUploadProgress?: (progressEvent: any) => void) {
        const formData = new FormData();
        formData.append("file", file);

        const config = {
          headers: { "Content-Type": "multipart/form-data" },
          // Callback allows something like this in the CTRL:
          // Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onUploadProgress,
        };

        try {
          const result = await HttpService.post(route, formData, config);

          return result.data;
        } catch (e) {
          this.$notify({
            title: "Error",
            message: "Upload failed.",
            type: "error",
          });

          throw e;
        }
    },
  },
});

export enum RoleName {
  IrisBackendUser = "IrisBackendUser",
  IrisAdministrator = "IrisAdministrator",
  CourseEditor = "CourseEditor",
  CourseTranslator = "CourseTranslator",
  RegionalTrainingManager = "RegionalTrainingManager",
  UnionTrainingManager = "UnionTrainingManager",
  UnionAdministrator = "UnionAdministrator",
  GlobalTrainingManager = "GlobalTrainingManager",
  Compliance = "Compliance",
  OnlineTrainingManager = "OnlineTrainingManager",
  AgentsAdministrator = "AgentsAdmin",
}

import { AgentApplicationStatus } from "@/core/constants";
import { 
  SimpleLookupModel,
  SimpleTextLookupModel,
  UploadFileModel,
  UserModel
 } from "@/core/models";

export class AgentApplicationCollectionModel {
  agentApplicationId: number;
  firstName: string;
  lastName: string;
  statusId: number;
  status: AgentApplicationStatus;
  statusName: string;
  unionName: string;
  addedOn: Date;
}

export class AgentApplicationListLookupModel {
  agentApplicationStatuses: SimpleLookupModel[] = [];
}

export class AgentApplicationModel {
  agentApplicationId: number;
  user: UserModel;
  firstName: string = "";
  lastName: string = "";
  knownAs: string = "";
  dateOfBirth: Date;
  address: string = "";
  companyName: string = "";
  companyAddress: string = "";
  numberOfClients: string = "";
  unionId: number;
  agentApplicationStatusId: number;
  nextBackendProgressionStatusId: number|null = null;
  uploadedFiles: UploadFileModel[] = [];
  proofOfIdentity: string = "";
  declarationFile: string = "";
  proofOfIdentityFiles: UploadFileModel[] = [];
  declarationFiles: UploadFileModel[] = [];
}

export class UpdateAgentApplicationLookupModel {
  agentApplicationStatuses: SimpleLookupModel[] = [];
  unions: SimpleLookupModel[] = [];
  numberOfClientsOptions: SimpleTextLookupModel[] = [];
}














import { Vue, Component, Prop } from "vue-property-decorator";

import { GridModel } from "@/core/models";

@Component
export default class GridSearch extends Vue {
  @Prop() grid: GridModel<any>;
  @Prop({ default: "Search" }) placeholder?: string;
  @Prop({ default: "search" }) cssClass: string;

  /**
   * Specifies the timout between key up and auto form submission
   *
   * @remarks
   * the unit is milliseconds. The default is 350ms
   *
   * @returns Tthe timout between key up and auto form submission
   */
  @Prop({ default: 350 }) submitOnChangeTimeout: number;

  search: string|undefined = "";
  debounce: number|undefined;
  
  created() {
    this.search = this.grid.filter.search
  }

  onChange() {
    if (this.grid.preventAutoSubmit) {
      this.grid.filter.search = this.search;

      return;
    }

    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.grid.onSearch(this.search);
    }, this.submitOnChangeTimeout);
  }

  onSubmit() {
    if (this.grid.preventAutoSubmit) {
      this.grid.onFilter();
    }
  }

  onClear() {
    if (this.grid.preventAutoSubmit) {
      this.search = "";
      this.grid.filter.search = this.search;
      
      return;
    }

    this.grid.onSearch("");
  }
}

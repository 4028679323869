













import { Vue, Component, Prop } from "vue-property-decorator";

import { GridModel } from "@/core/models";

@Component
export default class GridTextFilter extends Vue {
  @Prop() grid: GridModel<any>;
  @Prop() prop: string;
  @Prop() placeholder: string;

  /**
   * Specifies the timout between key up and auto form submission
   *
   * @remarks
   * the unit is milliseconds. The default is 350ms
   *
   * @returns Tthe timout between key up and auto form submission
   */
   @Prop({ default: 350 }) submitOnChangeTimeout: number;

  state:string = "";
  debounce: number|undefined;

  created() {
    this.state = (this.grid.filter as any)[this.prop];
  }

  onChange(value: any) {
    (this.grid.filter as any)[this.prop] = this.state;

    if (!this.grid.preventAutoSubmit) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.grid.onFilter();
      }, this.submitOnChangeTimeout);
    }
  }

  onSubmit() {
    if (this.grid.preventAutoSubmit) {
      this.grid.onFilter();
    }
  }

  onClear() {
    this.state = "";
    (this.grid.filter as any)[this.prop] = this.state;

    if (this.grid.preventAutoSubmit) {
      return;
    }

    this.grid.onFilter();
  }
}

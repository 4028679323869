export default {
  "common": {
    "name": "Имя",
    "hello": "Здравствуйте!",
    "details": "Подробности",
    "created": "Создано",
    "ok": "OK",
    "cancel": "Отменить",
    "save": "Сохранить",
    "unpublish": "",
    "id": "№",
    "search": "Поиск",
    "status": "Статус",
    "view": "Просмотреть",
    "course": "Курс",
    "union": "Союз",
    "noData": "Без данных",
    "startDate": "Дата начала курса",
    "startTime": "",
    "endDate": "Дата окончания курса",
    "notes": "Примечания",
    "educators": "Преподаватели",
    "forUser": "Для пользователя",
    "regions": "Регионы",
    "unions": "Союзам",
    "existingRegions": "",
    "existingUnions": "",
    "homeUnion": "",
    "expiresOn": "Срок действия до",
    "selectUser": "Выбрать пользователя",
    "none": "Ничего",
    "level": "Уровень",
    "actionAddAll": "Добавить все",
    "reason": "Причина",
    "gettingData": "Получение данных",
    "success": "Удачное завершение",
    "error": "Ошибка",
    "actionEdit": "Отредактировать",
    "actionSubmit": "Предоставить",
    "logo": "Логотип",
    "actionDropFile": "Сбросить файл сюда или",
    "imageFormatsAllowed1": "Разрешены файлы JPG/PNG/GIF",
    "forename": "Имя",
    "lastname": "Фамилия",
    "emailAddress": "Адрес эл. почты",
    "email": "Эл. почта",
    "validationIsRequired": "обязательно",
    "code": "Код",
    "certified": "Выдан сертификат",
    "certExpiry": "Срок действия сертификата",
    "session": "Сессия",
    "yes": "Да",
    "no": "Нет",
    "actionDownload": "Скачать",
    "close": "Закрыть",
    "user": "Пользователь",
    "location": "",
    "from": "",
    "to": "",
    "type": "",
    "validationError": "",
    "validationErrorDescription": "",
    "errorSaving": "",
    "saving": "",
    "addedOn": "",
    "address": "",
    "dateOfBirth": "",
    "reject": "",
    "saveAndProgressToNextStage": "",
    "nextStageIs": "",
    "warning": "",
    "auditors": "",
    "firstName": "Имя",
    "surname": "Фамилия"
  },
  "navigation": {
    "courses": "Курсы",
    "myCourses": "Мои курсы",
    "courseAdmin": "Админ курса",
    "training": "Тренировка",
    "mySessions": "Мои сессии",
    "sessionAdmin": "Администрация сессии",
    "dashboard": "Контрольная панель",
    "navAbout": "О",
    "navAdministration": "Администрации",
    "navUnions": "Союзы",
    "logout": "Выйти из системы",
    "myProfile": "Мой профиль",
    "coursesAttended": "Пройденные курсы",
    "licensesAwarded": "Выданные лицензии",
    "certification": "",
    "fileshare": "",
    "correspondence": ""
  },
  "mySessions": {
    "viewMyTrainingSession": "Просмотреть мои учебные сессии",
    "hostTrainingSession": "Провести учебную сессию",
    "selectCourse": "Пожалуйста, выберите курс",
    "selectUnion": "Пожалуйста, выберите союз",
    "selectLicense": "Пожалуйста, выберите лицензию",
    "licenseInfo": "Информация о лицензии",
    "validIn": "Действительна на",
    "language": "Язык",
    "unionToAdmin": "Управляющий союз"
  },
  "filters": {
    "filterByRegion": "Отфильтровать по региону",
    "filterByUnion": "Отфильтровать по союзу",
    "filterByStatus": "Отфильтровать по статусу",
    "filterByQualityStatus": "Отфильтровать по статусу качества",
    "filterByLevel": "Отфильтровать по уровню",
    "filterByStrand": "Отфильтровать по разделу",
    "filterByExpiry": "Отфильтровать по сроку действия",
    "filterByDeliveryType": "Отфильтровать  по типу проведения",
    "pleaseSelectLevel": "Пожалуйста, выберите уровень",
    "pleaseSelectStrand": "Пожалуйста, выберите раздел",
    "pleaseSelectRegions": "Пожалуйста, выберите регионы",
    "pleaseSelectUnions": "Пожалуйста, выберите союзы",
    "pleaseSelectStatus": "Пожалуйста, выберите статус",
    "filterByLanguage": "",
    "filterByCourse": "",
    "filterByAttendeeRegion": "",
    "filterByAttendeeUnion": "",
    "filterByUserEmail": "",
    "filterByAssessmentStatus": "",
    "filterByQualityAuditStatus": ""
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "Просмотреть запланированные учебные сессии",
    "scheduleTrainingSession": "Запланировать учебную сессию",
    "addTrainingSession": "Добавить учебную сессию"
  },
  "editTrainingSession": {
    "editTrainingSession": "Отредактировать учебную сессию",
    "scheduledTraining": "Запланированное обучение",
    "certificateLanguage": "Язык сертификата",
    "venueName": "Место проведения",
    "venueAddress": "Адрес места проведения",
    "organiser": "Организатор",
    "selectAnEducator": "Выбрать преподавателя",
    "addEducator": "Добавить преподавателя",
    "addNoteToHistory": "Добавить примечание к истории",
    "approve": "Одобрить",
    "saveTrainingSession": "Сохранить учебную сессию",
    "viewTrainingSessionHistory": "Просмотреть учебную историю",
    "noHistory": "Нет истории для просмотра",
    "addEducatorWarning": "Этот преподаватель может быть ассистентом, но не ведущим этой сессии.  Каждая сессия требует, как минимум, одного ведущего преподавателя. ",
    "assistingEducators": "Преподаватели-ассистенты",
    "canLead": "Может вести?",
    "selectLanguage": "Пожалуйста, выберите язык",
    "scheduleCourse": "Запланировать курс",
    "saveCourse": "Сохранить курс",
    "addCourse": "Добавить курс",
    "emailLanguageTooltip": "Сообщения участникам написаны на этом языке",
    "startTimeLocalTime": ""
  },
  "addNote": {
    "addNote": "Добавить примечание",
    "noteDetails": "Подробности примечания"
  },
  "courseSessionStatus": {
    "awaitingApproval": "В ожидании одобрения",
    "rejected": "Отказано",
    "approved": "Одобрено"
  },
  "tabs": {
    "tabattendees": "Участники",
    "tabhistory": "История",
    "tabCourseMaterials": "Материалы курса"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "Просмотреть участников учебной сессии",
    "editAttendeeList": "Отредактировать список участников",
    "attendeeName": "Имя",
    "assessmentStatus": "Статус оценки",
    "actions": "Действия",
    "editAttendees": "Отредактировать участников",
    "actionConfirm": "Подтвердить",
    "noUsersFound": "Участники не были найдены",
    "attendeeUpdateError": "Ошибка при обновлении участников",
    "attendeeUpdateErrorDescription": "При обновлении списка участников произошла ошибка. Был ли образец эл. почты сконфигурирован для локали сессии?",
    "optionSelectStatus": "Выбрать статус",
    "actionUpdateStatus": "Обновить статус",
    "actionAdd": "Добавить",
    "titleAddAssessment": "Добавить оценку",
    "selectLanguage": "Выбрать язык",
    "actionLicense": "Лицензия",
    "actionLicenseDescription": "Выдать лицензию этому участнику",
    "actionEdit": "Отредактировать",
    "unmetPrerequisites": "Неудовлетворенные предварительные условия",
    "approveAreYouSure": "Вы уверены, что вы хотите одобрить сертификацию для выбранных участников?",
    "approveCertification": "Одобрить сертификацию",
    "errorUpdatingStatusTitle": "Ошибка при обновлении статуса",
    "errorUpdatingStatusMessage": "При обновлении статуса участника произошла ошибка.",
    "sendAttendeeInvites": "",
    "sendAttendeeInvitesToolTip": ""
  },
  "editAssessment": {
    "editAssessment": "Отредактировать оценку",
    "competency": "Компетентность",
    "notYetCompetent": "Еще не компетентен",
    "notCompetent": "",
    "competent": "Компетентен",
    "excellent": "Отлично",
    "criteria": "Критерии",
    "commentHelpText": "Комментарий - обязательно для \"еще не компетентных\"",
    "actionSaveAndPublish": "Сохранить и опубликовать",
    "assessorComment": "Комментарий лица, проводящего оценку",
    "publishedBy": "Опубликовал(-а)",
    "lastUpdatedBy": "Обновил(-а) в последний раз",
    "addedBy": "Добавил(-а)",
    "errorSavingAssessment": "Ошибка при сохранении оценки",
    "errorSavingAssessmentMessage": "Проверьте правильность всей вводной информации",
    "confirmCancelAssessment": "Вы уверены, что хотите отменить?",
    "leaveAssessment": "Выйти из оценки?",
    "confirmPublishAssessment": "Вы уверены, что хотите опубликовать эту оценку? После опубликования эта оценка не может быть отредактирована. ",
    "publishAssessment": "Опубликовать оценку?",
    "viewAssessment": "Просмотреть оценку",
    "attendeeSelfReflection": "Самооценка участника",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "",
    "attendeeMayBeCompetent": "",
    "attendeeNotCompetent": "",
    "attendeeNotCompetentConfirmation": "",
    "unpublishAssessmentConfirmation": ""
  },
  "feedback": {
    "viewFeedback": "Просмотреть комментарии"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Статистика активных сертификатов",
    "date1": "Дата 1",
    "date2": "Дата 2",
    "activeCerts": "Активные сертификаты",
    "pickDate": "Выбрать дату",
    "total": "Всего"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Статистика выданных сертификатов",
    "filterByDeliveryType": "Отфильтровать  по типу проведения",
    "range1": "Диапазон 1",
    "range2": "Диапазон 2",
    "dateTo": "по",
    "regionUserFilters": "Фильтры региона /пользователя",
    "regionUserFiltersDescription1": "Фильтры по региону и союзу применяются по-разному в зависимости от типа проведения курса:",
    "regionUserFiltersDescription2": "Онлайн и Scorm: Статистика группируется по союзу регистрации участника",
    "regionUserFiltersDescription3": "Очный курс: Статистика группируется по союзу, в котором было проведено обучение.",
    "courseCode": "Код курса",
    "courseName": "Название курса",
    "issuedCerts1": "Выданные сертификаты 1",
    "issuedCerts2": "Выданные сертификаты 2",
    "percentageIncrease": "% увеличение",
    "range1Start": "Диапазон 1 начало",
    "range1End": "Диапазон 1 конец",
    "range2Start": "Диапазон 2 начало",
    "range2End": "Диапазон 2 конец"
  },
  "strands": {
    "strandRugbyReady": "Готовность к регби",
    "strandLaws": "Правила игры",
    "strandStrengthAndConditioning": "Силовая и физическая подготовка",
    "strandPlayerWelfare": "Благополучие игроков",
    "strandKeepRugbyClean": "За чистое регби",
    "strandCoaching": "Тренерская работа",
    "strandOfficiating": "Судейство",
    "strandIntegrity": "Цельность",
    "strandMatchDayStaff": "Персонал дня матча",
    "strand": "Раздел"
  },
  "licenseStatus": {
    "licenseActive": "Активная",
    "licenseRevoked": "Аннулированная",
    "licenseExpired": "Истек срок действия",
    "licenseProvisional": "Условная"
  },
  "licenses": {
    "licenses": "Лицензии",
    "grantEducatorLicense": "Выдать лицензию преподавателя",
    "actionRevoke": "Аннулировать",
    "grantALicense": "Выдать лицензию",
    "grantLicense": "Выдать лицензию",
    "certificatesAwarded": "Выданные сертификаты",
    "actionGrantALicense": "Выдать лицензию",
    "licenseRevoke": "Аннулирование лицензии",
    "revokeLicense": "Аннулировать лицензию",
    "noRevokeEmailHeader": "Без автоматического сообщения об аннулировании",
    "noRevokeEmailBody": "При аннулировании лицензии автоматическое сообщение эл. почты не отправляется. Администраторы могут предпочесть сообщить об этом лично.",
    "viewEligibleCourses": "Просмотреть  курсы, удовлетворяющие требованиям.",
    "eligibleCourses": "Подходящие курсы",
    "messageLicenceAwardDelay": "Существует задержка после присуждения сертификата до его выдачи участнику. Нижеприведенная таблица показывает только выданные сертификаты, поэтому возможно, что недавно присужденный сертификат не будет показан в этой таблице.",
    "messageEducatorCanTeach": "Преподаватель с предложенной лицензией может преподавать следующие курсы",
    "licenseAwardedTo": "Лицензия выдана",
    "errorAddingLicense": "При добавлении этой лицензии произошла ошибка",
    "gettingLicenseInformation": "Получение информации о лицензии",
    "noLicenses": "У вас нет активных лицензий в настоящее время.",
    "licenseHistory": "История лицензий",
    "qualityStatus": "Статус качества",
    "delayIssuingCertificate": "Примечание: Задержка в выдаче сертификатов",
    "delayIssuingCertificateInfo": "Существует задержка после присуждения сертификата до его выдачи участнику. Нижеприведенная таблица показывает только выданные сертификаты, поэтому возможно, что недавно присужденный сертификат не будет показан в этой таблице.",
    "noActiveLicenses": "Нет активных лицензий",
    "addLicenseError": "При добавлении этой лицензии произошла ошибка",
    "myLicenses": "Мои лицензии",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "",
    "licenseAmendedConfirmationHeading": "",
    "licenseAmendConfirmation": "",
    "expireLicense": "",
    "amendLicense": ""
  },
  "regionAdmin": {
    "rtmUser": "Пользователь RTM",
    "saveRegion": "Сохранить регион"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "Администратор Союза по обучению",
    "canDeliverCoursesForWr": "Может преподавать курсы от имени World Rugby",
    "saveUnion": "Сохранить Союз",
    "gettingUnion": "Добавление Союза",
    "unionSaved": "Союз сохранен"
  },
  "certStatus": {
    "certStatusInProgress": "В процессе выполнения",
    "certStatusValid": "действителен",
    "certStatusInExpired": "С истекшим сроком действия",
    "certStatusAll": "Все"
  },
  "userAdmin": {
    "userList": "Список пользователей",
    "inviteUser": "Пригласить пользователя",
    "infoNoEmailInviteHeader": "Эл. сообщение приглашения не будет отправлено.",
    "infoNoEmailInviteBody1": "Эта функция добавляет пользователя в базу данных с ролью сервера Iris",
    "infoNoEmailInviteBody2": "Эл. сообщение приглашения не будет отправлено пользователю, так как администраторы iris ответственны за то, чтобы сообщать им об их добавлении.",
    "infoNoEmailInviteBody3": "Если пользователь еще не зарегистрировался, он должен зарегистрироваться с WR SSO, используя адрес эл. почты, приведенный ниже.",
    "actionAddUserAndAssignBackendRole": " Добавить пользователя и определить роль сервера",
    "irisUserAddedHeader": "Добавлен пользователь сервера Iris",
    "irisUserAddedBody": "был добавлен в качестве пользователя сервера Iris.",
    "errorAddingUserHeader": "Ошибка при добавлении пользователя сервера.",
    "errorAddingUserBody": "Произошла ошибка при добавлении пользователя сервера.",
    "isExactEmail": "Точный адрес эл. почты",
    "userBaseId": "Имя пользователя",
    "lastLoginDate": "Дата последнего входа в систему",
    "coursesAndCertificates": "Курсы и сертификаты",
    "roles": "Роли",
    "currentValidCertificates": "Действительные сертификаты",
    "userLicenses": "Лицензии пользователя",
    "userNeverGrantedLicense": "Этому пользователю никогда не была выдана лицензия.",
    "infoRegionManagerheader": "Роль регионального менеджера по обучению",
    "infoRegionManagerDetail1": "Роль регионального менеджера по обучению была присуждена в",
    "infoRegionManagerDetail2": "административной форме региона",
    "infoUnionManagerheader": "Роль менеджера по обучению союза",
    "infoUnionManagerDetail1": "Роль менеджера по обучению союза была присуждена в",
    "infoUnionManagerDetail2": "административной форме союза",
    "userRoles": "роли пользователя",
    "infoRolesUpdatedHeader": "Роли обновлены",
    "infoRolesUpdatedBody": "Роли пользователя были успешно обновлены.",
    "userExactEmail": "Использовать точный адрес эл. почты",
    "select": "Выбрать",
    "noUsers": "Нет пользователей",
    "searchAttendee": "Искать пользователя для добавления"
  },
  "reports": {
    "reports": "Отчеты",
    "report": "Отчет",
    "deliveryType": "Тип подачи",
    "actionGetReport": "Получить отчет",
    "regions": "Регион(-ы)",
    "unions": "Союз(-ы)"
  },
  "qualityAudit": {
    "manage": "Управлять",
    "qualityAssurance": "Контроль качества",
    "qualityAudits": "Аудиты качества",
    "date": "Дата",
    "addQualityAudit": "Добавить аудит качества",
    "noAuditsAdded": "Аудиты качества не были добавлены к этой лицензии",
    "educatorCanTeach": "Преподаватель с предложенной лицензией может преподавать следующие курсы",
    "auditor": "Аудитор",
    "published": "Опубликовано",
    "actionPublish": "Опубликовать",
    "audits": "Аудиты",
    "assignAuditor": "Назначить аудитора",
    "myAssignedQas": "Мои назначенные аудиты контроля",
    "audit": "Аудит",
    "auditBy": "Аудит проведен",
    "publishedBy": "Аудит был опубликован ",
    "onDate": "на свидании",
    "venue": "Место проведения",
    "detailsPlaceholder": "",
    "areasOfStrength": "Сильные стороны",
    "areasOfStrengthPlaceholder": "Добавить сильные стороны сюда",
    "areasForDevelopment": "Области для развития",
    "areasForDevelopmentPlaceholder": "Добавить области для развития сюда",
    "agreedActionPlan": "Согласованный план действий",
    "agreedActionPlanPlaceholder": "Добавить согласованный план действий сюда",
    "educatorSelfReflection": "Самооценка преподавателя",
    "educatorSelfReflectionPlaceholder": "Добавить  самооценку преподавателя",
    "grade": "Балл",
    "gradePlaceholder": "Пожалуйста, выберите балл",
    "behaviour": "Поведение",
    "behaviourPlaceholder": "Пожалуйста, выберите поведение",
    "saveAsDraft": "Сохранить как черновик",
    "auditsOnEducator": "Аудиты преподавателя",
    "auditsByEducator": "Аудиты преподавателем",
    "educator": "Преподаватель",
    "addEducatorNote": "Добавить примечание о преподавателе",
    "educatorNoteTooltip": "Примечания о преподавателе будут видны преподавателям и их менеджерам в закладке истории курса",
    "qaSubmitSuccess": "Аудит качества был подан успешно",
    "qaPublishedSuccess": "Аудит качества был отправлен успешно",
    "qaConfirmPublish": "Вы уверены, что хотите опубликовать этот аудит качества? Аудит будет прочитан, как только вы его опубликуете.",
    "qaConfirmPublishTitle": "Опубликовать аудит?",
    "qaLoadingText": "Получение информации Аудита качества...",
    "manageAudits": "Управлять аудитами",
    "myActiveLicences": "Мои активные лицензии",
    "editQualityAudit": "Отредактировать аудит качества",
    "submitForm": "Отправить форму",
    "auditPublished": "Аудит опубликован",
    "user": "Пользователь",
    "licenseHolder": "Владелец лицензии",
    "licenseStatus": "",
    "publishStatus": "",
    "actionSubmitEducatorFeedback": "",
    "actionSubmitForEducatorFeedback": "",
    "filterByLicenseHolder": "",
    "filterByAuditor": "",
    "filterByLicenseExpiry": "",
    "filterByLicenseRegion": "",
    "filterByLicenseUnion": "",
    "filterByAuditStatus": "",
    "auditStartDate": "",
    "auditEndDate": "",
    "auditStatus": "",
    "auditDate": ""
  },
  "qualityAuditStatus": {
    "Draft": "",
    "AwaitingEducatorFeedback": "",
    "AwaitingAuditeeFeedback": "",
    "AwaitingPublish": "",
    "Published": ""
  },
  "termsAndConds": {
    "heading": "Принять Положения и условия World Rugby",
    "main": "Для того чтобы продолжить пользоваться Iris вы должны принять следующие положения и условия:",
    "educatorCodeOfConductHeading": "Кодекс поведения преподавателя",
    "educatorLinkDescription": "Кодекс поведения преподавателя можно просмотреть здесь",
    "educatorAcceptCodeOfConduct": "Я принимаю кодекс поведения преподавателя"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Удалить участников",
    "addAttendees": "Добавить участников",
    "addAttendeesBy": "Добавить участников по",
    "draft": "Черновик",
    "assessment": "Оценка",
    "noAttendees": "Нет участников",
    "noAttendeesSubTitle": "Участники не были добавлены к этому курсу Нажать на кнопку ниже для добавления участников",
    "bulkAddAttendees": "Групповое добавление участников по адресу эл. почты",
    "findExisting": "Поиск существующего пользователя",
    "registeringNew": "Регистрация нового пользователя",
    "bulkAddByEmailA": "Добавить нескольких участников к этому курсу. Ввести адреса эл. почты ниже, один адрес эл. почты на строку, и нажать на кнопку \"Добавить участников\".",
    "bulkAddByEmailB": "Только существующие пользователи Iris будут добавлены к этому курсу. Вам будет предоставлен список адресов эл. почты участников, которые не могли быть добавлены.",
    "addAttendeeLabel": "список адресов эл. почты участников (один на строку)",
    "addressesDoNotExist": "Адреса эл. почты участников, которые не существуют в Iris.",
    "emailsAreRegistered": "Следующие адреса эл. почты зарегистрированы в качестве пользователей.",
    "notPermittedToAccess": "",
    "emailsNotPermittedToAccess": "",
    "registerNewUser": "Зарегистрировать нового пользователя и добавить его как участника этого курса. Выполните это, только если пользователь ещё не существует в Iris.",
    "firstName": "Имя",
    "surname": "Фамилия",
    "noEmailWarning": "если у участника нет адреса эл. почты, оповещения будут отправлены преподавателю-организатору.",
    "registerNewUserAsAttendee": "Зарегистрировать нового пользователя как участника",
    "draftAttendeeList": "Ваш черновик списка участников",
    "updateAttendeeList": "Вы уверены, что хотите обновить список участников?",
    "confirmThisAction": "После подтверждения этого действия все заново добавленные участники получат вводное сообщение об этом курсе.",
    "confirmTitle": "Обновить список участников?",
    "errorAddingUserMessage": "Произошла ошибка при добавлении пользователя.",
    "errorAddingAttendees": "Произошла ошибка при добавлении участников."
  },
  "courseSessionHost": {
    "attendeeNotes": "Примечания для участников",
    "attendeeNotesTooltip": "Эти примечания появятся в пригласительных сообщениях для новых участников (после конфигурации в шаблоне эл. сообщения). Пожалуйста, напишите в выбранном языке.",
    "addCourse": "Добавить курс",
    "noEducatorLicensesDescription": "У вас нет активных лицензий. Вам нужны активные лицензии преподавателя для подачи курсов. Пожалуйста, свяжитесь с региональным менеджером по обучению для выдачи лицензии преподавателя.",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "Просмотреть  мои курсы",
    "viewScheduledCourses": "Просмотреть  запланированные курсы",
    "hostCourse": "Провести курс",
    "scheduleCourse": "Запланировать курс",
    "editCourse": "Отредактировать курс",
    "viewCourse": "Просмотреть курс",
    "viewAttendees": "Просмотреть участников",
    "viewCourseHistory": "Просмотреть историю курса"
  },
  "routeTags": {
    "acceptEducatorTerms": "Принять Положения и условия преподавателя"
  },
  "profileCourses": {
    "started": "Начат",
    "noCoursesAttended": "Нет пройденных курсов",
    "notAttendedAnyCourses": "Вы не прошли ни одного курса",
    "validCertLookupAll": "Все",
    "validCertLookupCurrent": "Действительные сертификаты",
    "validCertLookupExpired": "С истекшим сроком действия",
    "validCertLookupInProgress": "В процессе выполнения"
  },
  "profileLicenses": {
    "noLicenses": "Нет лицензий",
    "noLicensesMatch": "Нет лицензий, соответствующим критериям.",
    "expiry": "Срок истечения действия"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "Показать пригласительное сообщение",
    "attendeeEmailPreview": "Предварительный показ сообщения для участников",
    "subject": "Тема",
    "htmlContent": "Содержимое HTML",
    "textContent": "Текстовое содержание"
  },
  "myDashboard": {
    "myActions": "Мои действия",
    "recentCourseRequringApproval": "Недавние курсы, требующие одобрения",
    "attendeesAwaitingCertApproval": "Одобрение участников в ожидании сертификации",
    "underMyAdmin": "В рамках моей администрации",
    "recentCourses": "Недавние курсы",
    "upcomingCourses": "Предстоящие курсы",
    "auditsInProgress": "Аудиты в процессе выполнения",
    "myRecentCourses": "Мои недавние курсы",
    "awaitingApproval": "В ожидании одобрения",
    "myUpcomingCourses": "Мои предстоящие курсы",
    "myQualityAudits": "Мои аудиты качества",
    "myAuditsInProgress": "Мои аудиты в процессе выполнения",
    "myUpcomingAudits": "Мои предстоящие аудиты",
    "recentUpcomingCourses": "Недавние и предстоящие курсы",
    "noCoursesSubtitle": "У вас нет недавних или запланированных на ближайшее время курсов Нажать на кнопку ниже для проведения курса",
    "activeAudits": "Активные аудиты",
    "noAuditsSubtitle": "У вас нет недавних или запланированных на ближайшее время аудитов",
    "auditsRequiringMyFeedback": "",
    "assessmentPeriodDueToExpire": "",
    "assessmentsExpireInPastYear": "",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "",
    "fileshareCourseMaterials": ""
  },
  "correspondence": {
    "decline": "",
    "validateCorrespondence": "",
    "id": "",
    "emailScheduleType": "",
    "status": "",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "",
    "hideUsers": "",
    "attachments": "",
    "approve": "",
    "validate": ""
  },
  "educatorsEmail": {
    "numberOfUser": "",
    "noUsersFound": "",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "",
    "complianceCheckEdit": "",
    "complianceCheckAdd": "",
    "compliantOnDate": "",
    "complianceCheckSuccessfullySaved": "",
    "requiredCertificates": "",
    "usersToCheck": "",
    "confirmRemoveRequiredCertificate": "",
    "confirmRemoveUser": "",
    "errorAddingUsers": "",
    "noCertificates": ""
  },
  "educatorList": {
    "numberOfCoursesDelivered": "",
    "numberOfUnionsDeliveredIn": ""
  },
  "agents": {
    "agents": "",
    "applications": "",
    "updateAgentapplication": "",
    "knownAs": "",
    "companyName": "",
    "companyAddress": "",
    "numberOfClients": "",
    "declarationOfGoodCharacter": "",
    "proofOfidentity": "",
    "supportingDocumentation": "",
    "confirmReject": "",
    "confirmSaveAndProgress": "",
    "applicationSuccessfullyUpdated": "",
    "errorUpdatingApplication": "",
    "agentDoesNotExist": ""
  },
  "auditorList": {
    "numberOfAuditsAssigned": "",
    "numberOfStrandsAudited": "",
    "auditor": ""
  }
};
